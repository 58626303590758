import React from "react";
import Allmeds from "../Images/portfolio/Rectangle.png";
import helthcare from "../Images/portfolio/MacBook Pro 16.png";
import homecore from "../Images/portfolio/Group 1686553692.png";
import MIB from "../Images/portfolio/Nothing Phone 1.png";
import Gowild from "../Images/portfolio/MacBook Pro 17.png";
import "../Css/Home.css";
import "../Css/Portfolio.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import sliderlogo1 from "../Images/sliderlogo/Eicher-logo-1920x1080.png";
import sliderlogo2 from "../Images/sliderlogo/Group 1686552983.png";
import sliderlogo3 from "../Images/sliderlogo/Logo (1).png";
import sliderlogo4 from "../Images/sliderlogo/Logo.png";
import sliderlogo5 from "../Images/sliderlogo/Maharshi-Vitiligo-Centres-Logo 2.png";
import sliderlogo6 from "../Images/sliderlogo/RSFP-logo.png";
import sliderlogo7 from "../Images/sliderlogo/Sun-Pharma.png";
import sliderlogo8 from "../Images/sliderlogo/cropped-cropped-cbn-india-logo.png";
import sliderlogo9 from "../Images/sliderlogo/logonavbar_yolo 2.png";
import { Helmet } from "react-helmet";
import data from "../Constant/Data";

const Portfolio = () => {
  const settings1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
  };

  return (
    <div className="container-fluid">
      <Helmet>
        <tittle>Rashail Infotech Portfolio - IT Solutions</tittle>
        <meta
          name="description"
          title="IT Solutions Portfolio | Rashail Infotech"
          content="Explore our diverse portfolio showcasing innovative IT solutions and consulting expertise. Partner with us for your digital success."
        />

        <link
          rel="canonical"
          href="https://rashailinfotech.co.in/portfolio"
        ></link>
      </Helmet>
      <div className="row portfoliobackground">
        <div className="text-center mx-auto">
          <h1 className=" serviceheading mt-5">
            <span className="gradient-text-H">Highlighting </span> Our Diverse
            <br />
            Range of <span className="gradient-text">Projects</span>
          </h1>
          <p className="serviceparaheading ">
            Our projects span across sectors,Technologies,and
            challenges,demonstrating our capability <br />
            to excel in any endeavor
          </p>
          <div className="row justify-content-center align-items-center ">
            <div className="col-md-3 ">
              <h2>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "48px",
                  }}
                  className="gradient-text-portfolio-odometer  odometer-portfolio-mobile"
                >
                  4k+
                </span>
              </h2>
              <p className="serviceparaheading serviceparaheading-portfolio-mobile">
                Project Completed
              </p>
            </div>
            <div className="col-md-3 ">
              <h4>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "48px",
                  }}
                  className="gradient-text-portfolio-odometer odometer-portfolio-mobile"
                >
                  1.2k
                </span>
              </h4>
              <p className="serviceparaheading serviceparaheading-portfolio-mobile">
                Active Clients
              </p>
            </div>
            <div class="col-md-3">
              <h4>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "48px",
                  }}
                  className="gradient-text-portfolio-odometer odometer-portfolio-mobile"
                >
                  4+
                </span>
              </h4>
              <p className="serviceparaheading serviceparaheading-portfolio-mobile">
                Years Of Experience
              </p>
            </div>
            <div class="col-md-3">
              <h4>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "48px",
                  }}
                  className="gradient-text-portfolio-odometer odometer-portfolio-mobile"
                >
                  5k+
                </span>
              </h4>
              <p className="serviceparaheading serviceparaheading-portfolio-mobile">
                Happy Clients
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <h5 className="mt-4 mb-4 text-center mx-auto headingportfolio">
          Varied Brilliance on Display
        </h5>
        <p className=" text-center mx-auto paraheading">
          Our portfolio reflects our diverse range of accomplishments and
          expertise
        </p>

        <div className="row justify-content-center mb-20">
          {data?.map((project, index) => {
            return (
              <div key={index} className="col-md-3 mx-2 mt-5">
                <Link
                  to={project.link}
                  style={{ color: "#7738F8", textDecoration: "none" }}
                >
                  <div className="card card-with-shadow-portfolio w-">
                    <div className="bg-cover p-4 portfolio-img-container">
                      <img
                        src={project.projectimage}
                        className="h-72 w-full"
                        alt="..."
                      />
                    </div>
                    <div
                      className="card-body portfolio-fixed-card-body"
                      style={{
                        overflow: "hidden",
                        width: "100%",
                        height: "120px",
                      }}
                    >
                      <div
                        className=" text-sm flex flex-col items-center
            "
                      >
                        <h5 className="card-title mt-2 mb-2 portfoliocardheading text-3xl">
                          {project.title}
                        </h5>

                        <p
                          className="portfoliocardsubheading mx-auto"
                          style={{ padding: "4px" }}
                        >
                          {project.sticker_text}
                        </p>
                      </div>
                      {/* <div className="readmore-portfolio">Read More</div> */}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row teamfoliobackground d-flex justify-content-center align-items-center">
        <div className="col-md-6 text-start">
          <h4 className="teamupportfolio">
            Team up with us to bring <br /> your project to life
          </h4>
        </div>
        <div className="col-md-4">
          <Link
            to="/contactus"
            style={{ color: "#7738F8", textDecoration: "none" }}
          >
            <button className=" btn teamupportfoliobutton">Contact Us →</button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mx-auto text-center">
          <Slider className="custom-slider mt-5" {...settings1}>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo1}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo2}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo3}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo4}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo5}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo6}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo7}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo8}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo9}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
