import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    newsletter: false,
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Phone number validation
    if (formData.phone.length < 10) {
      setError("Phone number must be at least 10 digits long.");
      return;
    }

    try {
      const response = await axios.post(
        "https://preprod.dashboard.rashailagro.in/rashailenquiry/send-email/",
        {
          message: `You have received a new enquiry from ${formData.firstName} ${formData.lastName},\n Email - ${formData.email}\n Mob No.- ${formData.phone}\n Message: \n ${formData.message}`,
          server: "rashail-infotech",
          subject: "New Enquiry From Rashail Infotech website",
        },
        {
          headers: {
            Authorization:
              "Token " + "0ea888c04c1c996b41f4084cf25090b4a603d098",
            "Content-Type": "application/json",
          },
        }
      );
      setSuccess("Form submitted successfully");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      setError("Error submitting form. Please try again later.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h5 className="text-center mt-2 mb-2 paraheading">Contact Form</h5>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div className="row mt-2">
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="First Name"
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="Last Name"
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-6">
          <input
            type="email"
            className="form-control formplaceholder"
            placeholder="Email"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="Phone"
            required
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group mt-4">
        <textarea
          className="form-control formplaceholder"
          rows={6}
          name="message"
          placeholder="Write a message"
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <div className="custom-control custom-checkbox text-start mt-3 mb-3">
        <input
          type="checkbox"
          className="custom-control-input"
          id="customCheck1"
          name="newsletter"
          checked={formData.newsletter}
          onChange={handleChange}
        />
        <label
          className="custom-control-label fs-15 mx-1 formplaceholder"
          htmlFor="customCheck1"
        >
          Send a Newsletter
        </label>
      </div>
      <button type="submit" className="btn buttonfunctionaity col-md-10">
        Submit now
      </button>
    </form>
  );
};

export default ContactForm;
