import React, { useState } from "react";
import patternbackcontact from "../Images/pattern 4.png";
import MapComponent from "../Components/MapComponent ";
import { Helmet } from "react-helmet";
import ContactForm from "../Components/ContactusForm";
import PartnerForm from "../Components/PartnerForm";

const Contactus = () => {
  const [showProjectForm, setShowProjectForm] = useState(true);
  const [showPartnerForm, setShowPartnerForm] = useState(false);

  const handleProjectButtonClick = () => {
    setShowProjectForm(true);
    setShowPartnerForm(false);
  };

  const handlePartnerButtonClick = () => {
    setShowProjectForm(false);
    setShowPartnerForm(true);
  };

  return (
    <div className="container-fluid">
      <Helmet>
        <tittle>Contact Us - Rashail Infotech</tittle>

        <meta
          name="description"
          title="Contact Us - IT & Consulting Services"
          content="Reach out to Rashail Infotech for top-notch IT & consulting services. Let's discuss your project and business needs."
        />

        <link
          rel="canonical"
          href="https://rashailinfotech.co.in/contactus"
        ></link>
      </Helmet>

      <div class="row contactustopbackground">
        <div class="col-md-11 col-sm-12 text-center mx-auto mt-3">
          <h1 className="serviceheading mt-5">
            Let's <span className="gradient-text-contactus">start</span> a
            conversation
          </h1>
          <h2 className="serviceparaheading mt-4 mb-5">
            We are making an impact worldwide with our exceptional software
            solutions.
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h2 className="mainheading mt-5">Get in touch</h2>
          <h3 className="paraheading mt-4 mb-5">
            Drop us a line, or give us a heads up if you're interested in
            visiting us.
          </h3>
          <button
            className={
              showProjectForm ? "activeButton-contactus" : "noactive-contactus"
            }
            onClick={handleProjectButtonClick}
          >
            Have a Project
          </button>
          <button
            className={
              showPartnerForm ? "activeButton-contactus" : "noactive-contactus"
            }
            onClick={handlePartnerButtonClick}
          >
            Become Partner
          </button>
        </div>
      </div>
      <div
        className="row mt-5 mb-2 d-flex justify-content-center"
        style={{ position: "relative" }}
      >
        <div
          className="blockbackgrround-contactus"
          style={{
            position: "absolute",
            bottom: 8,
            left: 340,
            width: "150px",
            height: "150px",
            backgroundColor: "#7738F8",
            zIndex: -1,
          }}
        ></div>
        <div
          className="pattern-contacus"
          style={{
            position: "absolute",
            top: -36,
            right: 300,
            width: "100px",
            height: "100px",
            backgroundImage: `url(${patternbackcontact})`,
            backgroundSize: "cover",
            // zIndex: -2,
          }}
        ></div>
        <div className="col-md-6 mx-3 text-center">
          <div className="card contactus-card my-3" id="contact-card">
            {showProjectForm ? <ContactForm /> : null}
            {showPartnerForm ? (
              // <form>
              //   <div className="row mt-2">
              //     <div className="col-sm-6">
              //       <input
              //         type="text"
              //         className={`form-control formplaceholder`}
              //         placeholder="First Name"
              //         required=""
              //         name="name"
              //       />
              //     </div>
              //     <div className="col-sm-6">
              //       <input
              //         type="email"
              //         className={`form-control formplaceholder `}
              //         placeholder="Last Name"
              //         required=""
              //         name="email"
              //       />
              //     </div>
              //   </div>
              //   <div className="row mt-4 ">
              //     <div className="col-sm-6">
              //       <input
              //         type="text"
              //         className={`form-control formplaceholder `}
              //         placeholder="Email"
              //         required=""
              //         name="mobileno"
              //       />
              //     </div>
              //     <div className="col-sm-6">
              //       <input
              //         type="text"
              //         className={`form-control formplaceholder `}
              //         placeholder="Phone"
              //         required=""
              //         name="subject"
              //       />
              //     </div>
              //   </div>
              //   <div className="form-group mt-4 ">
              //     <textarea
              //       type="text"
              //       className={`form-control formplaceholder`}
              //       rows={6}
              //       name="comment"
              //       placeholder="Project Description"
              //     />
              //   </div>
              //   <div className="custom-control custom-checkbox text-start mt-3 mb-3">
              //     <input
              //       type="checkbox"
              //       className="custom-control-input"
              //       id="customCheck1"
              //     />
              //     <label
              //       className="custom-control-label fs-15 mx-1 formplaceholder"
              //       htmlFor="customCheck1"
              //     >
              //       Send a Newsletter
              //     </label>
              //   </div>
              //   <button
              //     type="submit"
              //     className="btn buttonfunctionaity col-md-10 "
              //   >
              //     Submit
              //   </button>
              // </form>
              <PartnerForm />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row items-center flex justify-content-center">
        <div className="col-md-5 sidemap mb-2 mt-3">
          <div className="col-md-12 col-sm-4 mt-5   py-2">
            <div className="footeraddress mx-4">
              <i
                className="fa fa-map-marker mx-1"
                aria-hidden="true"
                style={{ color: "white", marginRight: "8px" }}
              ></i>
              S2, Second Floor, Malwa Tower, Ashok Nagar, Indore,Madhya
              Pradesh-452001
            </div>
            <div className="mt-3 footeraddress mx-4">
              <i
                className="fa fa-phone"
                aria-hidden="true"
                style={{ color: "white", marginRight: "8px" }}
              ></i>
              +918602793619
            </div>
            <div className="mt-3 footeraddress mx-4">
              <i
                className="fa fa-envelope"
                aria-hidden="true"
                style={{ color: "white", marginRight: "8px" }}
              ></i>
              info@rashailinfotech.co.in <br />
              <span className="mx-4">sales@rashailinfotech.co.in</span>
            </div>
            {/* <div className="mt-2 followcontactus mx-4">
                            Follow our social network
                            <div className="mt-2">
                                <span><i className="fa fa-facebook facebook-mobile-c" aria-hidden="true" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                <span><i className="fa fa-instagram facebook-mobile-c" aria-hidden="true" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                <span><i className="fa fa-twitter facebook-mobile-c" aria-hidden="true" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                <span><i className="fa fa-youtube-play facebook-mobile-c" aria-hidden="true" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                <span><i className="fa fa-linkedin facebook-mobile-c" aria-hidden="true" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                            </div>
                        </div> */}
          </div>
          {/* <img src={sidemap} alt="sidemap" className="img-fluid" /> */}
        </div>
        <div className="col-md-6 " style={{ width: "50%", height: "400px" }}>
          <div className="card contactus-card my-3" id="mapcomponent-c">
            <MapComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
