import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Components/Footer";
import Services from "./Pages/Services";
import Portfolio from "./Pages/Portfolio";
import Aboutus from "./Pages/Aboutus";
import Contactus from "./Pages/Contactus";
import Portfoliodetails from "./Pages/Portfoliodetails";
import Career from "./Pages/Career";
import ScrollToTop from "./Components/scroll";
import Privacypolicy from "./Pages/Privacypolicy";
import Termscondition from "./Pages/Termscondition";
import ApplicationFormModal from "./Components/Applicationformmodel";
import ProductDetailPage from "./Pages/ProductDetailPage";
import data from "./Constant/Data";

function App() {
  const dynamicRoutes = data.map((route, index) => (
    <Route
      key={index}
      exact
      path={`/portfolio/:${route.link}`}
      element={<ProductDetailPage />}
    />
  ));

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/services" element={<Services />} />

        <Route exact path="/aboutus" element={<Aboutus />} />
        <Route exact path="/contactus" element={<Contactus />} />

        <Route exact path="/career" element={<Career />} />
        <Route exact path="/privacypolicy" element={<Privacypolicy />} />
        <Route exact path="/termscondition" element={<Termscondition />} />
        <Route
          exact
          path="/applicationFormModal"
          element={<ApplicationFormModal />}
        />

        <Route exact path="/portfolio" element={<Portfolio />} />
        {dynamicRoutes}
      </Routes>
      <ScrollToTop />
      <ToastContainer />
      <Footer />
    </Router>
  );
}

export default App;
