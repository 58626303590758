import React from "react";
import "../Css/Home.css";
import card1 from "../Images/card1.png";
import card2 from "../Images/card2.png";
import card3 from "../Images/card3.png";
import productprocess from "../Images/Our Process.png";
import servicedm from "../Images/service/servicedm.jpg";
import industries from "../Images/Industries We are in.png";
import pattern4 from "../Images/pattern 4.png";
import industry1 from "../Images/home/home-industry-1.png";
import industry2 from "../Images/home/home-industry-2.png";
import industry3 from "../Images/home/home-industry-3.png";
import industry4 from "../Images/home/home-industry-4.png";
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <div className="container-fluid">

<Helmet>
<title>Our Services - IT & Consulting Solutions</title>
<meta name="description" content="Explore our range of IT & consulting solutions designed to optimize your business processes and drive growth." />


<link rel="canonical" href="https://rashailinfotech.co.in/services">
</link>
</Helmet>
      <div className="row servicetopbackground">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h1 className=" serviceheading mt-5">
            IT and <span className="gradient-text-excellence">Consulting </span>{" "}
            Services for{" "}
            <span className="gradient-text-excellence">Businesses</span>
          </h1>
          <h2 className="serviceparaheading mt-4 mb-5">
            Explore our IT and Consulting services below to discover how we can
            help you achieve your business goals. We're experts in providing
            bespoke solutions that further your interests.
          </h2>
        </div>
      </div>
      <div className="row text-center mx-auto mt-5">
        <h2 className="mt-3 mainheading">
          IT and Consulting Solutions tailored just for You!
        </h2>
        <p className="paraheading">
          We pride ourselves on delivering a comprehensive suite of IT and
          Consulting services designed to meet your unique needs. Whether you're
          looking to enhance your IT infrastructure or explore ingenious cloud
          solutions, our team of experts is here to guide you every step of the
          way.
        </p>
        <div className="col-md-6 mt-5">
          <img
            src={servicedm}
            alt="Landingimg"
            className="img-fluid w-[600px] mb-4"
          
          />
        </div>

        <div className="col-md-6 mt-5 flex flex-col items-start justify-center">
          <h3 className=" fromvision">Turning Dreams into Code</h3>
          <p className="paraheading text-start ">
            We specialize in turning your technological aspirations into
            tangible results. Our team of IT engineers blend innovation,
            strategy, and expertise to bring your dreams to life.
          </p>
        </div>
      </div>
      <div className="row comprehensiveservices">
        <h5 className="mt-4 mb-4 text-center mx-auto comprehensiveserviceshead">
          Explore our Comprehensive Services
        </h5>
        <p className="comprehensiveservicespara text-center mx-auto">
          Dive into our wide-ranging services to find the perfect fit for your
          business needs.
          <br /> From IT solutions to expert consulting, we offer everything you
          need.
        </p>
        <div className="row justify-content-center align-items-center mt-5 mb-5">
          <div className="col-md-3 mx-4 ">
            <div
              className="card comprehensiveservices mobile-card-service"
              style={{ width: "20rem", height: "40rem" }}
            >
              <img
                src={card1}
                className="card-img-top"
                alt="..."
                style={{ background: "white" }}
              />
              <div className="card-body">
                <h5
                  className="card-title mobile-card-service-h mt-2 mb-3"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  Website Development
                </h5>
                <p className="card-text servicecardpara">
                  We master the arts of development for both Dynamic and Static
                  Websites which marks your Business presence in the market,
                  highlighting your USPs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mx-4">
            <div
              className="card comprehensiveservices mobile-card-service"
              style={{ width: "20rem", height: "40rem" }}
            >
              <img
                src={card2}
                className="card-img-top"
                alt="..."
                style={{ background: "white" }}
              />
              <div className="card-body ">
                <h5
                  className="card-title mobile-card-service-h mt-2 mb-3"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  Mobile App Development
                </h5>
                <p className="card-text servicecardpara">
                  We excel in creating Mobile Apps for Android as well and iOS,
                  using Hybrid Platform, which reduces Production Time and
                  Developmental Cost.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mx-4">
            <div
              className="card comprehensiveservices mobile-card-service"
              style={{ width: "20rem", height: "40rem" }}
            >
              <img
                src={card3}
                className="card-img-top"
                alt="..."
                style={{ background: "white" }}
              />
              <div>
                <h5
                  className="card-title mobile-card-service-h mt-4  "
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  UI/UX Development
                </h5>
                <p className="card-text servicecardpara mt-4">
                  We are committed to understanding your Business, vision and
                  Dream and Transform them into Reality and deliver it directly
                  to your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="btn seemorebtn text-center mx-auto mb-5">
          See More
        </button>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-10 mx-auto text-center">
          <h2 className="mainheading">
            Our IT and Consulting services methodology
          </h2>
          <h3 className="paraheading">
            We pride ourselves on a structured and agile product development
            process that ensures the creation of innovative and high-quality IT
            solutions. Our approach encompasses several key stages.
          </h3>
          <div className="mt-5">
            <img src={productprocess} alt="Landingimg" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="row text-center whytochoose justify-content-center">
        <div className="col-md-5 text-center mt-5">
          <h2 className="mt-3 mainheading text-start">Why to Choose Us?</h2>
          <h4 className="text-start whytochooseheading mt-4">
            <span className="checkbox"></span>Expert Team
          </h4>
          <p className="text-start paraheading mt-4"  >
            Our team consists of highly skilled professionals with extensive
            experience in IT, ensuring that you receive top-tier expertise.
          </p>
          <h4 className="text-start whytochooseheading mt-4">
            <span className="checkbox"></span>Tailored Solution
          </h4>
          <p className="text-start paraheading mt-4">
            We understand that one size doesn't fit all. Our solutions are
            customized to meet your unique business needs.
          </p>
          <h4 className="text-start whytochooseheading mt-4">
            <span className="checkbox"></span>Responsive Support
          </h4>
          <p className="text-start paraheading mt-4">
            Our dedicated support team is available around the clock to address
            your concerns promptly.
          </p>
        </div>
        <div className="col-md-5 mt-5 ">
          <img
            src={servicedm}
            alt="Landingimg"
            className="img-fluid mt-5 service-mobile"
            style={{ width: "100%", height: "80%" }}
          />
        </div>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-md-10 text-center">
          <h2 className="mainheading">
            Secure the Success of Your IT Initiative
          </h2>
          <h3 className="paraheading">
            Rashail Infotech is India's most unique and innovative product
            development company; working closely with Enterprises, SMEs, and
            startups to mentor their executive decision-making and product
            launches.
          </h3>
        </div>
      </div>
      <div className="row services-excel-mobile">
        <div className="col-md-1"></div>
        <div className="col-md-5 mt-5">
          <div className="row mt-5">
            <div className="col-sm mt-5">
              <div style={{ position: "relative" }}>
                <img
                  src={industry1}
                  alt="Landingimg"
                  className="img-fluid mx-2 mt-3"
                  style={{
                    width: "250px",
                    height: "150px",
                    borderRadius: "10px",
                  }}
                />
              </div>

              <img
                src={industry2}
                alt="Landingimg"
                className="img-fluid mx-2 mt-4"
                style={{
                  width: "250px",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
              <img
                src={industry3}
                alt="Landingimg"
                className="img-fluid mx-2 mt-4"
                style={{
                  width: "250px",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div
              className="col-sm d-flex flex-column justify-content-center align-items-center mb-2"
              style={{ position: "relative" }}
            >
              <div
                className="odometer text-center mb-2 mx-2"
                style={{ backgroundColor: "#7738F8", position: "relative" }}
              >
                <h2
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                  }}
                >
                  4k+
                </h2>
                <span className="d-block mt-3 projectcompleted">
                  Project Completed
                </span>
              </div>
              <div
                className="pattern4"
                style={{
                  position: "absolute",
                  bottom: -50,
                  right: -30,
                  width: "150px",
                  height: "150px",
                  backgroundImage: `url(${pattern4})`,
                  backgroundSize: "cover",
                  zIndex: -1,
                }}
              ></div>

              <img
                src={industry4}
                alt="Landingimg"
                className="img-fluid mx-5 mt-5"
                style={{
                  width: "200px",
                  height: "300px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="half-image">
            <img
              src={industries}
              alt="Landingimg"
              className="img-fluid"
              style={{ maxWidth: "1047px" }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 text-center">
        <div className="col-10 mx-auto">
          <h2 className="mainheading">Frequently Asked Questions</h2>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2
                className="accordion-header paraheading"
                id="flush-headingOne"
              >
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What services does your IT company offer?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  From concepts to execution, operationalization, and ongoing
                  updates, We offer end-to-end, comprehensive service; You can
                  rest assured that your project needs will be met and exceeded
                  with Rashail Infotech..
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Do you offer customized solutions for specific industries?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  Yes, at Rashail Infotech, we take an all-inclusive strategy to
                  understanding the intricacies of your business and how we,
                  from our industry background; optimize your processes, address
                  your unique challenges, and deliver a custom-made digital
                  solution.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What industries have you served in the past?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  Rashail Infotech has worked across a number of different
                  domains, including the Healthcare sector, Food Industry,
                  Automobile Manufacturing, Industrial Automation, Agri-Tech,
                  IoT Applications, and a lot more. We excel at collaborations,
                  working closely with our customers and jointly devising
                  pragmatic and effective solutions.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Do you offer training for staff on your IT company?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  Yes, we educate and upskill staff regularly and keep them
                  well-versed in the intricacies of the software.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  How can I reach your support team in case of an emergency or
                  urgent issue?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  It's easy to connect with us, at any moment in time by calling
                  us at +91 8602793619; or getting in touch with your dedicated
                  project manager, assigned to you by Rashail Infotech.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingSix">
                <button
                  className="accordion-button collapsed paraheading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  What technology stack and tools do you typically use for
                  development?
                </button>
              </h2>
              <div
                id="flush-collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body paraheading text-start">
                  {" "}
                  At Rashail Infotech, we use present-day, modern technologies
                  to help us achieve client objectives and goals. Here's a quick
                  peek at our tech stacks and tools:
                  <ul>
                    <li>
                      Micro-controllers & Microprocessors: AVR, STM, PIC, ARM
                      Cortex
                    </li>
                    <li>
                      Wireless technology: LoRA, Zigbee, BLE, Bluetooth, WiFi,
                      nRF
                    </li>
                    <li>All Analog & Digital Sensors</li>
                    <li>Cloud Tech: AWS, Custom Servers</li>
                    <li>Protocols: HTTP, HTTPS, MQTT, CoAP</li>
                    <li>Computer Vision & Data Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
