import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../Images/Rashail Infotech Logo.png";
import { Link, useLocation } from "react-router-dom";
import "../Css/Home.css";

function Navbar() {
  const location = useLocation();
  const isRouteActive = (path) => {
    return location.pathname === path;
  };

  // Apply the gradient-text class conditionally based on the current route
  const getLinkClass = (path) => {
    return isRouteActive(path)
      ? " gradient-text-navbar"
      : "nav-link font-weight-bold";
  };

  const handleLinkClick = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler) {
      navbarToggler.click(); // Simulate a click on the navbar toggler
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        {/* Logo Image */}
        <Link className="navbar-brand mx-5" to="/">
          <img src={Logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className=" text-xl font-semibold" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto">
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/")}
              to="/"
            >
              Home
            </Link>
            <span style={{ margin: "0 10px" }}></span>{" "}
            {/* Add a separator (e.g., a vertical bar) with some margin */}
            {/* <Link className="nav-link  font-weight-bold " to="/aboutus">About Us</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/aboutus")}
              to="/aboutus"
            >
              About Us
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link font-weight-bold" to="/services">Services</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/services")}
              to="/services"
            >
              Services
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link  font-weight-bold" to="/portfolio">Portfolio</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/portfolio")}
              to="/portfolio"
            >
              Portfolio
            </Link>
            <span style={{ margin: "0 10px" }}></span>
            {/* <Link className="nav-link  font-weight-bold" to="#">Contact Us</Link> */}
            <Link
              onClick={handleLinkClick}
              className={getLinkClass("/contactus")}
              to="/contactus"
            >
              Contact Us
            </Link>
            <span style={{ margin: "0 40px" }}></span>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
