import React, { useState, useRef } from "react";
import "../Css/Home.css";
import Landingimg from "../Images/Landing Image.png";
import industries from "../Images/Industries We are in.png";
import card1 from "../Images/card1.png";
import card2 from "../Images/card2.png";
import card3 from "../Images/card3.png";
import productprocess from "../Images/Our Process.png";
import techuse from "../Images/Tech we use.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import contactimg from "../Images/contactimage.jpg";
import pattern4 from "../Images/pattern 4.png";
import { Link } from "react-router-dom";

import industry1 from "../Images/home/home-industry-1.png";
import industry2 from "../Images/home/home-industry-2.png";
import industry3 from "../Images/home/home-industry-3.png";
import industry4 from "../Images/home/home-industry-4.png";
import ourportfolio1 from "../Images/home/our-portfolio-1.png";
import ourportfolio2 from "../Images/home/our-portfolio-2.png";
import ourportfolio3 from "../Images/home/our-portfolio-3.png";
import ourportfolio4 from "../Images/home/our-portfolio-4.png";
import ourblog1 from "../Images/home/ourblog-1.png";
import ourblog2 from "../Images/home/ourblog-2.png";
import ourblog3 from "../Images/home/ourblog-3.png";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";

import sliderlogo1 from "../Images/sliderlogo/Eicher-logo-1920x1080.png";
import sliderlogo2 from "../Images/sliderlogo/Group 1686552983.png";
import sliderlogo3 from "../Images/sliderlogo/Logo (1).png";
import sliderlogo4 from "../Images/sliderlogo/Logo.png";
import sliderlogo5 from "../Images/sliderlogo/Maharshi-Vitiligo-Centres-Logo 2.png";
import sliderlogo6 from "../Images/sliderlogo/RSFP-logo.png";
import sliderlogo7 from "../Images/sliderlogo/Sun-Pharma.png";
import sliderlogo8 from "../Images/sliderlogo/cropped-cropped-cbn-india-logo.png";
import sliderlogo9 from "../Images/sliderlogo/logonavbar_yolo 2.png";
import { Helmet } from "react-helmet";
import ContactusForm from "../Components/ContactusForm";
import FAQ from "../Components/FAQ";

const Home = () => {
  const [showShareLinks, setShowShareLinks] = useState(false);

  const handleShareClick = () => {
    setShowShareLinks(!showShareLinks);
  };
  const [selectedCategory, setSelectedCategory] = useState("all");
  const productProcessRef = useRef(null);

  const scrollToProductProcess = () => {
    if (productProcessRef.current) {
      productProcessRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const images = [
    { id: 1, category: "all", src: ourportfolio4 },
    { id: 2, category: "technology", src: ourportfolio1 },
    { id: 3, category: "design", src: ourportfolio2 },
    { id: 4, category: "development", src: ourportfolio3 },
    { id: 5, category: "ideas", src: ourportfolio4 },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <div className="container-fluid ">
      <Helmet>
        <title>Rashail Infotech - Premier IT & Consulting Services</title>
        <meta
          name="description"
          content="Take your business to new heights with Rashail Infotech! Explore our IT & consulting services designed to fuel your success in today's emerging cyberspace."
        />
        <link rel="canonical" href="https://rashailinfotech.co.in/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Organization",
              "@id": "#Organization",
              "url": "https://rashailinfotech.co.in/",
              "legalName": "Rashail Infotech",
              "name": "Rashail Infotech",
              "description": "Take your business to new heights with Rashail Infotech! Explore our IT & consulting services designed to fuel your success in today's emerging cyberspace.",
              "logo": "https://images.app.goo.gl/9hpjNv5hUzyzs8DZ9",
              "telephone": "+918602793619",
              "email": "info@rashailinfotech.co.in",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "S2, Second Floor, Malwa Tower",
                "addressLocality": "Ashok Nagar",
                "addressRegion": "Indore, Madhya Pradesh",
                "addressCountry": "India",
                "postalCode": "452001"
              },
              "sameAs": [
                "https://www.linkedin.com/showcase/rashail-infotech/"
              ]
            }
          `}
        </script>
      </Helmet>

      <div className="row topbackground ">
        <div class="col-md-1"></div>
        <div className="col-md-5">
          <h1 className="mt-5 buildbusiness ">
            IT and
            <span className="gradient-text-business">
              {" "}
              Consulting services{" "}
            </span>{" "}
            to power
            <p className="mt-3">
              up
              <span className="gradient-text-business"> your business.</span>
            </p>
          </h1>
          <span className="mt-5 paraheading ">
            <h2>
              Further your market position <br />
              with expert IT and Consulting services
            </h2>
          </span>
          <div className="mt-5">
            <Link to="/contactus" className="">
              <button
                onClick={scrollToProductProcess}
                className="btn text-xl buttonfunctionaity rounded-lg "
              >
                Talk to an Expert
              </button>
            </Link>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src={Landingimg}
            alt="'IT and Consulting Services by Rashail Infotech'"
            className="img-fluid"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 mx-auto text-center">
          <Slider className="custom-slider mt-5" {...settings1}>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo1}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo2}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo3}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo4}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo5}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo6}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo7}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo8}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div style={{ width: "112px", height: "54px" }}>
              <img
                src={sliderlogo9}
                alt="Imag 1"
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </div>
          </Slider>
        </div>
      </div>

      <div className="row justify-content-center mt-5 " ref={productProcessRef}>
        <div class="col-md-10 text-center">
          <h2 class="mainheading">
            Our IT and Consulting services methodology
          </h2>
          <h3 className="paraheading">
            We pride ourselves on a structured and agile product development
            process that ensures the creation of innovative and high-quality IT
            solutions. Our approach encompasses several key stages.
          </h3>
          <div className="mt-5 flex justify-center">
            <img src={productprocess} alt="Landingimg" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="row mt-5 industriesmobile justify-content-center">
        <div class="col-md-10 text-center mt-5">
          <h2 class="mainheading">Secure the Success of Your IT Initiative</h2>
          <h3 className="paraheading">
            Rashail Infotech is India's most unique and innovative product
            development company; working closely with Enterprises, SMEs, and
            startups to mentor their executive decision-making and product
            launches.
          </h3>
        </div>
      </div>
      <div className="row industriesmobile">
        <div class="col-md-1"></div>
        <div class="col-md-5 mt-5">
          <div className="row mt-5">
            <div className="col-sm mt-5">
              <div style={{ position: "relative" }}>
                <img
                  src={industry1}
                  alt="Landingimg"
                  className="img-fluid mx-2 mt-3"
                />
              </div>

              <img
                src={industry2}
                alt="Landingimg"
                className="img-fluid mx-2 mt-4"
                style={{
                  width: "250px",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
              <img
                src={industry3}
                alt="Landingimg"
                className="img-fluid mx-2 mt-4"
                style={{
                  width: "250px",
                  height: "150px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div
              className="col-sm d-flex flex-column justify-content-center align-items-center mb-2"
              style={{ position: "relative" }}
            >
              <div
                className="odometer text-center mb-2 mx-2"
                style={{ backgroundColor: "#7738F8", position: "relative" }}
              >
                <h4
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                  }}
                >
                  4k+
                </h4>
                <span className="d-block mt-3 projectcompleted">
                  Project Completed
                </span>
              </div>
              <div
                className="pattern4"
                style={{
                  position: "absolute",
                  bottom: -50,
                  right: 0,
                  width: "150px",
                  height: "150px",
                  backgroundImage: `url(${pattern4})`,
                  backgroundSize: "cover",
                  zIndex: -1,
                }}
              ></div>

              <img
                src={industry4}
                alt="Landingimg"
                className="img-fluid mx-5 mt-5"
                style={{
                  width: "200px",
                  height: "300px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="half-image">
            <img
              src={industries}
              alt="Landingimg"
              className="img-fluid"
              style={{ maxWidth: "1047px" }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2 mt-4  justify-content-center">
        <div class="col-md-10 text-center">
          <h2 class="mainheading">Check Out Our Tech Stack</h2>
          <div class="d-flex flex-wrap mt-4">
            <div class="card flip-card card-with-shadow-home mb-2 mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front" style={{ width: "22rem" }}>
                  <img src={card1} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h3 class="card-title  gradient-text-home-card">
                      Website Development
                    </h3>
                  </div>
                </div>
                <div
                  class="flip-card-back"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  <h3 class="card-title cardflipheading mt-4 mb-4 mx-4">
                    Website Development
                  </h3>
                  <h4 className="cardflipparaheading mx-4">
                    Mark your business presence in cyberspace with Dynamic and
                    Static websites from Rashail Infotech. Functional,
                    beautifully designed, and light as a feature, our skilled
                    web developers are making the magic happen, one sliver of
                    code at a time.
                  </h4>
                  <p
                    className="text-start mx-4"
                    style={{
                      color: "#7738F8",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    <Link
                      to="/services"
                      style={{ color: "#7738F8", textDecoration: "none" }}
                    >
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div class="card flip-card  card-with-shadow-home mb-2 mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front" style={{ width: "22rem" }}>
                  <img src={card2} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h3 class="card-title  gradient-text-home-card">
                      Mobile App Development
                    </h3>
                  </div>
                </div>
                <div
                  class="flip-card-back"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  <h3 class="card-title cardflipheading mt-4 mb-4 mx-4">
                    Mobile App Development
                  </h3>
                  <h4 className="cardflipparaheading mx-4">
                    We excel in creating Mobile Apps for Android as well and
                    ios,using Hybrid Platform,which reduces Production Time and
                    Developmental Cost.
                  </h4>
                  <p
                    className="text-start mx-4"
                    style={{
                      color: "#7738F8",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    <Link
                      to="/services"
                      style={{ color: "#7738F8", textDecoration: "none" }}
                    >
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div class="card flip-card  card-with-shadow-home mb-2 mx-auto">
              <div class="flip-card-inner">
                <div class="flip-card-front" style={{ width: "22rem" }}>
                  <img src={card3} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h3 class="card-title  gradient-text-home-card">
                      UI/UX Design
                    </h3>
                  </div>
                </div>
                <div
                  class="flip-card-back"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                >
                  <h3 class="card-title cardflipheading mt-4 mb-4 text-start mx-4">
                    UI/UX Design
                  </h3>
                  <h4 className="cardflipparaheading mx-4">
                    We are committed to understanding your Business,Vision and
                    Dream and Tranform them into Reality and deliver it directly
                    to your customers.{" "}
                  </h4>
                  <p
                    className=" mx-4"
                    style={{
                      color: "#7738F8",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    <Link
                      to="/services"
                      style={{ color: "#7738F8", textDecoration: "none" }}
                    >
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="card flip-card mt-2 card-with-shadow-home mb-2 mx-auto"
              style={{ backgroundColor: "#7738F8" }}
            >
              <Link
                to="/services"
                style={{ color: "#7738F8", textDecoration: "none" }}
              >
                <div className="flip-card">
                  <div
                    className="flip-card home-card-c-mobile"
                    style={{
                      width: "22rem",
                      height: "22rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="seemore" style={{ cursor: "pointer" }}>
                      See More
                    </div>
                    <div
                      style={{
                        width: "53px",
                        height: "53px",
                        borderRadius: "50%",
                        backgroundColor: "#21143D",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fa fa-arrow-right"
                        style={{
                          fontSize: "24px",
                          color: "#FFFFFF",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-2 justify-content-center">
        <div class="col-md-10 text-center">
          <h2 class="mainheading">Technologies We Use</h2>
          <h3 className="paraheading">
            At Rashail, we pride ourselves on being at the forefront of
            technological innovation. Our commitment to providing top-notch IT
            Services is backed by our multidisciplinary expertise, coupled with
            cutting-edge technologies we use to meet our clients' needs. Here is
            a glimpse into the technology stack we leverage to deliver
            exceptional solutions.
          </h3>
          <div className="mt-5 flex justify-center">
            <img src={techuse} alt="Landingimg" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-2">
        <div className="col-12 text-center">
          <h2 className="mainheading">Our Portfolio</h2>
          <ul className="nav justify-content-center mb-3">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedCategory === "all" ? "active" : ""
                } portfoliolist`}
                onClick={() => handleCategoryChange("all")}
                style={{ cursor: "pointer" }}
              >
                #All
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedCategory === "technology" ? "active" : ""
                } portfoliolist`}
                onClick={() => handleCategoryChange("technology")}
                style={{ cursor: "pointer" }}
              >
                Technology
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedCategory === "design" ? "active" : ""
                } portfoliolist`}
                onClick={() => handleCategoryChange("design")}
                style={{ cursor: "pointer" }}
              >
                Design
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedCategory === "development" ? "active" : ""
                } portfoliolist`}
                onClick={() => handleCategoryChange("development")}
                style={{ cursor: "pointer" }}
              >
                Development
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedCategory === "ideas" ? "active" : ""
                } portfoliolist`}
                onClick={() => handleCategoryChange("ideas")}
                style={{ cursor: "pointer" }}
              >
                Ideas
              </a>
            </li>
          </ul>
          <div className="d-flex flex-wrap justify-content-center">
            {images
              .filter(
                (image) =>
                  selectedCategory === "all" ||
                  image.category === selectedCategory
              )
              .map((image) => (
                <div key={image.id} className="mx-2 mt-3">
                  <img
                    src={image.src}
                    alt="Portfolio"
                    className="img-fluid rounded"
                    style={{ width: "270px", height: "371px" }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      {/*       
      <div class="row mt-5 mb-5 client-feedback-mobile justify-content-end">

        <h2 className="mainheading text-center mb-5">Our Clients Feedback</h2>
        <div className="col-md-4 text-end center-vertically">
          <div className="d-flex">
            <button
              className="circular-button mx-2"
              onClick={goToPreviousSlide}
            >
              <i className="fa fa-chevron-left" />
            </button>
            <button className="circular-button  mx-2" onClick={goToNextSlide}>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </div>
        <div className="col-md-8 text-center">
          <div className="testimonial-slider-container">
            <Slider ref={sliderRef} {...sliderSettings}>
              <div className="testimonial-slide">
                <div
                  className="card card-slider-home-client mx-2 m-5"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="card-body">
                    <div className="testimonial-content">
                      <div className="testimonial-image d-flex">
                        <img
                          src={mentestimonial}
                          alt="Andrew's Image"
                          className="img-fluid rounded-circle blue-border"
                        />
                        <div className="testimonial-text mx-2">
                          <h4 className="slidercardheading">Andrew josh</h4>
                          <h5 className="slidercardsubheading">
                            CEO at tech solution Inc
                          </h5>
                        </div>
                      </div>
                      <p className="slidercard">
                        Rashail infotech has been an absolute lifesaver for my
                        business. Their team of experts provided top-notch IT
                        support and tailored solutions that have significantly
                        improved our productivity
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slide">
                <div
                  className="card card-slider-home-client mx-2 mt-5"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="card-body">
                    <div className="testimonial-content">
                      <div className="testimonial-image d-flex">
                        <img
                          src={mentestimonial}
                          alt="Andrew's Image"
                          className="img-fluid rounded-circle blue-border"
                        />
                        <div className="testimonial-text mx-2">
                          <h4 className="slidercardheading">Andrew josh</h4>
                          <h5 className="slidercardsubheading">
                            CEO at tech solution Inc
                          </h5>
                        </div>
                      </div>
                      <p className="slidercard">
                        Rashail infotech has been an absolute lifesaver for my
                        business. Their team of experts provided top-notch IT
                        support and tailored solutions that have significantly
                        improved our productivity
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slide">
                <div
                  className="card card-slider-home-client mt-5"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="card-body">
                    <div className="testimonial-content">
                      <div className="testimonial-image d-flex">
                        <img
                          src={mentestimonial}
                          alt="Andrew's Image"
                          className="img-fluid rounded-circle blue-border"
                        />
                        <div className="testimonial-text mx-2">
                          <h4 className="slidercardheading">Andrew josh</h4>
                          <h5 className="slidercardsubheading">
                            CEO at tech solution Inc
                          </h5>
                        </div>
                      </div>
                      <p className="slidercard">
                        Rashail infotech has been an absolute lifesaver for my
                        business. Their team of experts provided top-notch IT
                        support and tailored solutions that have significantly
                        improved our productivity
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        <div className="col-md-12 text-center">
          <Link
            to="/portfolio"
            style={{ color: "#7738F8", textDecoration: "none" }}
          >
            <button className="btn buttonfunctionaity viewallmobile mt-5">
              View All
            </button>
          </Link>
        </div>
      </div> */}

      <div className="row mt-5 mb-2 text-center faqmobile">
        <FAQ />
      </div>
      <div className="row mt-5 mb-5 justify-content-center">
        <h2 className="mainheading mx-5 mb-5 text-center">View Our Blog</h2>
        <div className="col-md-10 d-flex ">
          <div
            className="card industryeco"
            style={{ width: "367px", height: "413px" }}
          >
            <img
              src={ourblog1}
              className="card-img-top ml-5 mobileblockimg img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title cardheading">
                In-Depth Industry and Ecosystem Analysis
              </h5>
              <p className="blogpara mt-2 d-flex justify-content-between">
                SEPTEMBER 13, 2023. 6 COMMENTS
                <span
                  className="blogparashareicon"
                  style={{ cursor: "pointer" }}
                  onClick={handleShareClick}
                >
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </span>
              </p>
            </div>
          </div>

          <div
            className="card industryeco mx-2"
            style={{ width: "367px", height: "413px" }}
          >
            <img
              src={ourblog2}
              className="card-img-top ml-5 mobileblockimg img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title cardheading">
                15 Best PHP Frameworks for <br />
                2023
              </h5>
              <p className="blogpara mt-2 d-flex justify-content-between">
                SEPTEMBER 13, 2023. 6 COMMENTS
                <span
                  className="blogparashareicon"
                  style={{ cursor: "pointer" }}
                  onClick={handleShareClick}
                >
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </span>
              </p>
            </div>
          </div>
          <div
            className="card industryeco"
            style={{ width: "367px", height: "413px" }}
          >
            <img
              src={ourblog3}
              className="card-img-top ml-5 mobileblockimg img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title cardheading">
                15 Secrets of Digital Transformation
              </h5>
              <p className="blogpara mt-2 d-flex justify-content-between">
                SEPTEMBER 13, 2023. 6 COMMENTS
                <span
                  className="blogparashareicon"
                  style={{ cursor: "pointer" }}
                  onClick={handleShareClick}
                >
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-2 justify-content-center">
        <div className="col-md-10 text-center">
          <h2 className="mainheading">Contact Us</h2>
          <h3 className="paraheading">
            We believe communication is the key to any global business. The more
            frequently we communicate, the better we start understanding each
            other and build a strong trust between us.
          </h3>
        </div>
      </div>
      <div className="row mt-5 mb-2 d-flex justify-content-center">
        {/* <div className="col-md-6 mx-3 text-center">
          <div className="card my-3" id="contact-card">
            <form>
              <h5 className="text-start mt-2 mb-2 paraheading">Contact Form</h5>
              <div className="row mt-2">
                <div className="col-sm-6">
                  <input
                    type="text"
                    className={`form-control formplaceholder`}
                    placeholder="First Name"
                    required=""
                    name="name"
                  />
                </div>
                <div className="col-sm-6 ">
                  <input
                    type="text"
                    className={`form-control formplaceholder `}
                    placeholder="Last Name"
                    required=""
                    name="name"
                  />
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-sm-6">
                  <input
                    type="email"
                    className={`form-control formplaceholder `}
                    placeholder="Email"
                    required=""
                    name="email"
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className={`form-control formplaceholder `}
                    placeholder="Phone"
                    required=""
                    name="subject"
                  />
                </div>
              </div>
              <div className="form-group mt-4 ">
                <textarea
                  type="text"
                  className={`form-control formplaceholder`}
                  rows={6}
                  name="comment"
                  placeholder="Write a message"
                />
              </div>
              <div className="custom-control custom-checkbox text-start mt-3 mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label
                  className="custom-control-label fs-15 mx-1 formplaceholder"
                  htmlFor="customCheck1"
                >
                  Send a Newsletter
                </label>
              </div>
              <button
                type="submit"
                className="btn buttonfunctionaity col-md-10 "
              >
                Submit now
              </button>
            </form>
          </div>
        </div> */}
        <div className="col-md-6 mx-3 text-center">
          <div className="card my-3" id="contact-card">
            <ContactusForm />
          </div>
        </div>

        <div
          className="col-md-4  image-container"
          style={{ position: "relative" }}
        >
          <div
            className="blockbackgrround"
            style={{
              position: "absolute",
              bottom: 19,
              left: 0,
              width: "150px",
              height: "150px",
              backgroundColor: "#7738F8",
              zIndex: -1,
            }}
          ></div>
          <div
            className="pattern4"
            style={{
              position: "absolute",
              top: -70,
              right: 0,
              width: "150px",
              height: "150px",
              backgroundImage: `url(${pattern4})`,
              backgroundSize: "cover",
              zIndex: -2,
            }}
          ></div>
          <img
            src={contactimg}
            alt="Landingimg"
            className="img-fluid image-with-shadow homepatternmo"
            style={{ width: "429px", height: "471px" }}
          />
        </div>
      </div>
      {/* Share Modal */}
      {showShareLinks && (
        <Modal show={showShareLinks} onHide={handleShareClick}>
          <Modal.Header closeButton>
            <Modal.Title>Share Links</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Your share links content */}
            <div>
              {/* Facebook share icon */}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      window.location.href
                    )}`,
                    "facebook-share-dialog",
                    "width=626,height=436"
                  )
                }
              >
                <i className="fa fa-facebook mx-3" aria-hidden="true"></i>
              </a>
              {/* WhatsApp share icon */}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    `whatsapp://send?text=${encodeURIComponent(
                      "Check out this post: " + window.location.href
                    )}`,
                    "whatsapp-share-dialog",
                    "width=626,height=436"
                  )
                }
              >
                <i className="fa fa-whatsapp mx-3" aria-hidden="true"></i>
              </a>
              {/* Instagram share icon */}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    `https://www.instagram.com/share?url=${encodeURIComponent(
                      window.location.href
                    )}`
                  )
                }
              >
                <i className="fa fa-instagram mx-3" aria-hidden="true"></i>
              </a>
              {/* Twitter share icon */}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      window.location.href
                    )}&text=${encodeURIComponent("Check out this post!")}`
                  )
                }
              >
                <i className="fa fa-twitter mx-3" aria-hidden="true"></i>
              </a>
              {/* LinkedIn share icon */}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                      window.location.href
                    )}`
                  )
                }
              >
                <i className="fa fa-linkedin mx-3" aria-hidden="true"></i>
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleShareClick}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Home;
