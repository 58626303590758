import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="col-10 mx-auto text-base text-start">
      <h2 className="text-4xl mt-10 text-center text-purple-700 font-bold mb-6">Frequently Asked Questions</h2>
      <div className="space-y-6" id="accordionFlushExample">
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(1)}
            >
              <span className=' font-normal text-2xl'>What services does your IT company offer?</span>
              <span>{activeIndex === 1 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 1 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="p-4">
              From concepts to execution, operationalization, and ongoing updates, We offer end-to-end, comprehensive service; You can rest assured that your project needs will be met and exceeded with Rashail Infotech.
            </div>
          </div>
        </div>
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(2)}
            >
              <span  className='font-normal text-2xl'>Do you offer customized solutions for specific industries?</span>
              <span>{activeIndex === 2 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 2 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="p-4">
              Yes, at Rashail Infotech, we take an all-inclusive strategy to understanding the intricacies of your business and how we, from our industry background; optimize your processes, address your unique challenges, and deliver a custom-made digital solution.
            </div>
          </div>
        </div>
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(3)}
            >
              <span className='font-normal text-2xl'>What industries have you served in the past?</span>
              <span>{activeIndex === 3 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 3 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="p-4">
              Rashail Infotech has worked across a number of different domains, including the Healthcare sector, Food Industry, Automobile Manufacturing, Industrial Automation, Agri-Tech, IoT Applications, and a lot more.
              We excel at collaborations, working closely with our customers and jointly devising pragmatic and effective solutions.
            </div>
          </div>
        </div>
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(4)}
            >
              <span className='font-normal text-2xl'>Do you offer training for staff on your IT company?</span>
              <span>{activeIndex === 4 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 4 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="p-4">
              Yes, we educate and upskill staff regularly and keep them well-versed in the intricacies of the software.
            </div>
          </div>
        </div>
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(5)}
            >
              <span className='font-normal text-2xl'>How can I reach your support team in case of an emergency or urgent issue?</span>
              <span>{activeIndex === 5 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 5 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
          <div className="p-4">
  It's easy to connect with us, at any moment in time by calling us at 
  <a href="tel:+918602793619" className="text-blue-500 underline"> +91 8602793619</a>; 
  or getting in touch with your dedicated project manager, assigned to you by Rashail Infotech.
</div>

          </div>
        </div>
        <div className="border-b">
          <h2 className="mb-0">
            <button
              className="w-full text-left flex justify-between items-center py-2 focus:outline-none"
              type="button"
              onClick={() => handleToggle(6)}
            >
              <span className='font-normal text-2xl'>What technology stack and tools do you typically use for development?</span>
              <span>{activeIndex === 6 ? '-' : '+'}</span>
            </button>
          </h2>
          <div className={`transition-all duration-300 ${activeIndex === 6 ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="p-4">
              At Rashail Infotech, we use present-day, modern technologies to help us achieve client objectives and goals. Here's a quick peek at our tech stacks and tools:
              <ul className="list-disc ml-6 mt-2">
                <li>Micro-controllers & Microprocessors: AVR, STM, PIC, ARM Cortex</li>
                <li>Wireless technology: LoRA, Zigbee, BLE, Bluetooth, WiFi, nRF</li>
                <li>All Analog & Digital Sensors</li>
                <li>Cloud Tech: AWS, Custom Servers</li>
                <li>Protocols: HTTP, HTTPS, MQTT, CoAP</li>
                <li>Computer Vision & Data Analytics</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
