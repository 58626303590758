import React, { Component } from 'react';

class MapComponent extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <iframe
          title="Google Maps"
          width="100%"
          height="100%"
          frameBorder="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.9672292628617!2d75.8659906!3d22.6961796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd3c0a8ef0fd%3A0x406b8e6797d06f57!2sRashail%20Tech%20Labs%20Private%20Limited!5e0!3m2!1sen!2sin!4v1637443937854!5m2!1sen!2sin"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default MapComponent;