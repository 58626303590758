import React from "react";
import Mission from "../Images/aboutus/Group 1686553697.png";
import Vision from "../Images/aboutus/vision.png";
import Goal from "../Images/aboutus/Goal.png";
import ourmission from "../Images/aboutus/ourmission.png";
import whytochoose from "../Images/aboutus/Pexels Photo by fauxels.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Aboutus = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          About Rashail Infotech - IT & Consulting Services Provider
        </title>
        <meta
          name="description"
          content="Learn about Rashail Infotech innovative IT solutions and consulting services. Trusted experts in digital transformation for your business."
        />

        <link
          rel="canonical"
          href="https://rashailinfotech.co.in/aboutus"
        ></link>
      </Helmet>

      <div className="row aboutustopbackground">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h1 className=" serviceheading mt-5 ">
            {" "}
            Leveraging{" "}
            <span className="gradient-text-aboutus"> Technology </span>
            <br /> to Achieve{" "}
            <span className="gradient-text-aboutus"> Brilliant Results</span>
          </h1>
          <h2 className="serviceparaheading mt-4 mb-5">
            Rashail Infotech, your trusted partner for cutting-edge IT solutions
            and unparalleled support, driving your business towards cyber
            supremacy.
          </h2>
        </div>
      </div>
      <div className="row aboutsecondbackground mx-5 mt-5">
        <div className="col-md-6"></div>
        <div className="col-md-6 mx-auto mt-5">
          <h2 className="mainheading mt-5 mx-4">About Us</h2>
          <h3 className="paraheading mt-3 mx-4">
            Rashail Infotech focuses our entire firm on talent, brilliant minds,
            and technology. We are defined by our people, stay at the forefront
            of technological developments, and are devoted to transending client
            expectations.
          </h3>
          <span className="gradient-text-aboutus-next mx-4">
            Above & Beyond
          </span>
        </div>
      </div>
      <div className="row justify-content-center">
        <h2 className="mainheading mt-5 mx-4 text-center">
          Our Mission & Vision
        </h2>
        <div class="col-md-4 text-center">
          <img src={ourmission} alt="Landingimg" className="img-fluid mt-5" />
        </div>
        <div class="col-md-6 d-flex flex-column ">
          <h3 className="text-start whytochooseheading flex items-center jc">
            <span>
              <img src={Mission} alt="Imag 1" className="img-fluid " />
            </span>
            Mission
          </h3>
          <h4 className="text-start paraheading mx-5">
            Adding value to terrific businesses through technology; We opened
            our doors on the principle that our customers should get results—not
            just reports; from the people they trust, us.
          </h4>
          <h3 className="text-start whytochooseheading  flex items-center">
            <span>
              <img src={Vision} alt="Imag 1" className="img-fluid" />
            </span>
            Vision
          </h3>
          <h4 className="text-start paraheading mx-5">
            Impacting our clients' top and bottom lines, with mathematical
            clarity, precision, and technology.
          </h4>
          <h4 className="text-start whytochooseheading  flex items-center">
            <span>
              <img src={Goal} alt="Image 1" className="img-fluid" />
            </span>
            Goal
          </h4>
          <p className="text-start paraheading mx-5">
            Our dedicated support team is available around the clock to address
            your concerns promptly.
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-5 mb-5">
        <div class="col-md-6 text-center">
          <h2 className="mainheading text-start">Why choose us? </h2>
          <h3 className="paraheading text-start">
            Your time is valued and your business goals are our goals. We are
            organizationally efficient, tech-heavy, and equipped with the
            sharpest minds in the industry; We recognize that continued
            satisfaction of client needs is imperative to business viability. We
            diligently work towards securing a long-term partnership with each
            of our customers, till perpetuity.
          </h3>
        </div>
        <div class="col-md-4 ">
          <img src={whytochoose} alt="Landingimg" className="img-fluid " />
        </div>
      </div>
      <div className="row aboutusbottombackground mb-5 d-flex align-items-center">
        <div className="col-md-11 col-sm-12 text-center mx-auto">
          <h2 className="aboutbottomheading mt-5"> Get in touch</h2>
          <h3 className="aboutparabottomheading mt-4 mb-5">
            Drop us a line, or give us a heads up if you're interested in
            visiting us.
          </h3>
          <Link
            to="/contactus"
            style={{ color: "#7738F8", textDecoration: "none" }}
          >
            <button className="aboutcontactbtn mb-5">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
