import React from "react";
import Helmet from "react-helmet";
import StickerImg from "../Images/Gowild Portfolio/Group 1686554265.png";
import portfolioImg from "../Images/Portfolio_page.png";
import data from "../Constant/Data";
import "../Css/Portfolio.css";

const ProductDetailPage = () => {
  const url = new URL(window.location.href);
  const path = url.pathname.split("/").pop();
  const projectData = data.find((project) => project.link === path);

  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <title>{projectData.meta.title}</title>
        <meta name="description" content={projectData.meta.description} />
        <link rel="canonical" href={projectData.canonicalTag} />
      </Helmet>
      <div>
        {/* Header */}
        <div className="flex flex-col md:flex-row items-center justify-around px-10 md:px-32 bg-[#2F2F2F] py-6">
          <div className="">
            <h1 className="gradient-text-business text-5xl font-semibold">
              {projectData.title}
            </h1>
            <p className="text-white text-xl">
              {projectData.clientRequirements.subheading}
            </p>
          </div>
          <div className="flex flex-col justify-center">
            <div className="project-detail-img-container">
              <img src={projectData?.categoryIMage} alt="" className="" />
            </div>
            <div className="flex justify-center">
              <p className="text-white">{projectData?.imageTitle}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 mx-10 md:mx-24 text-center">
        <h2 className="text-[#7738F8]">
          Unleash Your Inner Explorer and Embark on the Adventure of a Lifetime!
        </h2>
        <p className="text-xl">{projectData.clientRequirements.subheading}</p>
      </div>

      <div className="p-10 md:px-32 grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-3">
        {/* Render cards section */}
        {Object.values(projectData.cardsSection).map((card) => (
          <div key={card.title} className="h-60 p-4 border shadow-md">
            <div className="flex flex-row items-center justify-start">
              <img src={card.img} alt="" className="m-2 mx-4 h-14" />
              <h2 className="text-xl">{card.title}</h2>
            </div>
            <p>{card.subheading}</p>
          </div>
        ))}
      </div>

      {/* Render project challenges section */}
      <div className="bg-gradient-to-tl from-[#B93ED1] to-[#7738F8] items-center justify-center flex flex-col md:flex-row md:px-32 p-6 gap-10">
        <div>
          <h2 className="text-white text-4xl">Project Challenges</h2>
          <p className="text-white text-xl my-4">
            {projectData.timelineSection.projectChallenges.paragragh}
          </p>
        </div>
        <img src={portfolioImg} alt=" " className="h-96" />
      </div>

      {/* Render stages of software development lifecycle */}
      <div className="px-14">
        <div className="mt-20 my-10">
          <h2 className="text-[#7738F8] text-center">
            7 Stages of the Software Development Life Cycle
          </h2>
        </div>

        {/* Iterate over stages and render content */}
        {Object.values(
          projectData.timelineSection.stagesOfSoftwareDevelopmentLifeCycle
        ).map((stage, index) => (
          <div key={stage.title} className=" xl:mx-28 overflow-hidden">
            {index % 2 === 0 ? (
              <>
                <div className="flex flex-row items-center justify-around bg-gradient-to-r from-[#F1F1F1] to-white">
                  <img src={stage.icon} alt="" className="h-60 p-3" />
                  <div className="basis-4/12">
                    <h3 className="text-xl">{stage.title}</h3>
                    <p>{stage.content}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center justify-around bg-gradient-to-r from-white to-[#F1F1F1]">
                  <div className="basis-4/12">
                    <h3 className="text-xl">{stage.title}</h3>
                    <p>{stage.content}</p>
                  </div>
                  <img src={stage.icon} alt="" className="h-60 p-3" />
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Render current scenario section */}
      <div className="flex flex-col md:flex-row items-center justify-around mx-10 md:mx-32 my-10">
        <div>
          <h2 className="text-[#7738F8]">
            {projectData.currentScenario.title}
          </h2>
          <p>{projectData.currentScenario.subheading}</p>
        </div>
        <img
          src={projectData.projectimage}
          alt=""
          className="h-60 w-auto px-4"
        />
      </div>
    </div>
  );
};

export default ProductDetailPage;
