import React, { useState } from "react";

const PartnerForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    projectDescription: "",
    sendNewsletter: false,
  });
  const [success, setSuccess] = useState("");

  const [errors, setErrors] = useState({
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      return "Phone number must be exactly 10 digits.";
    }
    return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneError = validatePhone(formData.phone);

    if (phoneError) {
      setErrors({ ...errors, phone: phoneError });
      return;
    }
    setSuccess("Form submitted successfully");
    console.log("Form Data:", formData);
    // Add form submission logic here
  };

  return (
    <form onSubmit={handleSubmit}>
      {errors?.phone && <p style={{ color: "red" }}>{errors?.phone}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div className="row mt-2">
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="First Name"
            required
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="Last Name"
            required
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-6">
          <input
            type="email"
            className="form-control formplaceholder"
            placeholder="Email"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control formplaceholder"
            placeholder="Phone"
            required
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <div className="text-danger mt-1">{errors.phone}</div>
          )}
        </div>
      </div>
      <div className="form-group mt-4">
        <textarea
          className="form-control formplaceholder"
          rows={6}
          name="projectDescription"
          placeholder="Project Description"
          value={formData.projectDescription}
          onChange={handleChange}
        />
      </div>
      <div className="custom-control custom-checkbox text-start mt-3 mb-3">
        <input
          type="checkbox"
          className="custom-control-input"
          id="customCheck1"
          name="sendNewsletter"
          checked={formData.sendNewsletter}
          onChange={handleChange}
        />
        <label
          className="custom-control-label fs-15 mx-1 formplaceholder"
          htmlFor="customCheck1"
        >
          Send a Newsletter
        </label>
      </div>
      <button type="submit" className="btn buttonfunctionaity col-md-10">
        Submit
      </button>
    </form>
  );
};

export default PartnerForm;
