import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/Rashail Infotech Logo.png";
import "../Css/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "./loaders/Spinner";

const Footer = () => {
  const DivStyle = {
    backgroundColor: "#2F2F2F",
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (error) {
      setError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setError("Please enter your email id");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setError("");
    setSuccess("");
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://preprod.dashboard.rashailagro.in/rashailenquiry/send-email/",
        {
          message: `
        ${email} user is asking for newsletter and free insights`,

          server: "rashail-infotech",
          subject: "New Enquire From Rashail Infotech website",
        },
        {
          headers: {
            Authorization:
              "Token " + "0ea888c04c1c996b41f4084cf25090b4a603d098",
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setIsLoading(false);
        setSuccess("Form submitted successfully");
        setEmail("");
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      }

      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      setError("Error submitting form. Please try again later.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row" style={DivStyle}>
        {/* First Column */}
        <div
          className="col-md-4 col-sm-4 px-5 py-2"
          style={{
            backgroundColor: "#7738F8",
            color: "white",
            fontSize: "16px",
            borderRadius: "0 0 40px 0",
          }}
        >
          <a className="navbar-brand mx-4" href="#">
            <img src={Logo} alt="Logo" className=" mx-10" />
          </a>
          <p className="footerhead py-2 mx-4">
            {" "}
            An IT Division of <br /> Rashail Tech Labs Private Limited
          </p>
          <div className="footeraddress mx-4">
            <i className="fa fa-map-marker mx-1" aria-hidden="true"></i>
            S2, Second Floor, Malwa <br />
            &nbsp;&nbsp;&nbsp; Tower, Ashok Nagar, <br />
            &nbsp;&nbsp;&nbsp; Indore,Madhya Pradesh-
            <br />
            &nbsp;&nbsp;&nbsp;452001
          </div>
          <div className="mt-3 footeraddress mx-4">
            <i
              className="fa fa-phone"
              aria-hidden="true"
              style={{ color: "white", marginRight: "8px" }}
            ></i>
            +918602793619
          </div>
          <div className="mt-3 footeraddress mx-4">
            <i
              className="fa fa-envelope"
              aria-hidden="true"
              style={{ color: "white", marginRight: "8px" }}
            ></i>
            info@rashailinfotech.co.in <br />
            <span className="mx-4">sales@rashailinfotech.co.in</span>
          </div>
        </div>

        {/* Second Column */}
        <div className="col-md-8 col-sm-8">
          <div className="row justify-content-center">
            {/* Quick Links Column */}
            <div className="col-md-3 col-sm-3 mb-4 px-4">
              <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead">
                Quick Links
              </p>
              <ul className="list-unstyled mb-0">
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/career"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Career
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/portfolio"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Portfolio
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="#"
                    className="text-gray hover-white footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Blogs
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/privacypolicy"
                    className="text-gray hover-white footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Privacy Policy
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/termscondition"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Terms Of Use
                  </Link>
                </li>
              </ul>
            </div>
            {/* Pages Column */}
            <div className="col-md-3  col-sm-3 mb-4 px-4">
              <p className="fs-16 mb-3 mt-3 letter-spacing-05 footerhead">
                Pages
              </p>
              <ul className="list-unstyled mb-0">
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Home
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/aboutus"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    About Us
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/services"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Services
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/portfolio"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Portfolio
                  </Link>
                </li>
                <li className="py-2" style={{ textAlign: "left" }}>
                  <Link
                    to="/contactus"
                    className="text-gray hover-white  footerlinkheading"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#7738F8" }}
                    />{" "}
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* Newsletter Column */}
            <div className="col-md-5 col-sm-3 px-4">
              <p className="fs-16 mb-3 letter-spacing-05 mt-3 footerhead">
                Newsletter
              </p>
              <div className="footerhead py-2">
                Sign up for our newsletter to <br />
                get news and free insight
              </div>

              <div className="col-md-4 d-none d-md-block col-lg-4 mb-6 mb-lg-0  text-center mt-2">
                <input
                  type="email"
                  className=" p-2 inputfooter"
                  placeholder="Email"
                  required
                  name="email"
                  value={email}
                  onChange={handleChange}
                />
              </div>

              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}

              <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 text-center mt-3 d-none d-md-block">
                <button
                  type="submit"
                  className={`subscribefooter flex justify-center items-center ${
                    isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Subscribe"}
                </button>
              </div>

              <div className="mt-2 followfooter">
                Follow our social network
                <div className="mt-2">
                  {/* <span><i className="fa fa-facebook facebook-mobile" aria-hidden="true" style={{ color: '#7738F8', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                  <span><i className="fa fa-instagram facebook-mobile" aria-hidden="true" style={{ color: '#7738F8', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                  <span><i className="fa fa-twitter facebook-mobile" aria-hidden="true" style={{ color: '#7738F8', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                  <span><i className="fa fa-youtube-play facebook-mobile" aria-hidden="true" style={{ color: '#7738F8', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span> */}

                  <span>
                    <a
                      href="https://www.linkedin.com/company/rashail-tech-labs/mycompany/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-linkedin facebook-mobile"
                        aria-hidden="true"
                        style={{
                          color: "#7738F8",
                          fontSize: "26px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Copyright Section */}
          <div className="row  justify-content-center mt-5">
            <div
              className="col-md-10 col-sm-10 copyright-mobile-footer text-end"
              style={{
                color: "#FBFBFB",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "18px",
                borderTop: "1px solid #CCCCCC",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Copyright © 2024 Rashail Infotech, All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
